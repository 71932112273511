import axios from "axios"

// Create an Axios instance with a base URL
const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL ||
    "https://polished-truth-42233.botics.co/"
})

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem("auth_token")
    if (token) {
      config.headers["Authorization"] = `Token ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Response interceptor
api.interceptors.response.use(
  response => {
    // You can modify the response data here
    return response
  },
  error => {
    // Handle errors here
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Response error:", error.response.data)
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Request error:", error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error:", error.message)
    }
    return Promise.reject(error)
  }
)

// Generic request function
const apiRequest = async (method, url, data = null, config = {}) => {
  try {
    const response = await api({
      method,
      url,
      data,
      ...config
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export default apiRequest
