import React from "react"

const TabButtons = ({ tabs, activeTab, onTabClick }) => {
  return (
    <div style={styles.header}>
      {tabs.map(tab => (
        <button
          key={tab}
          style={{
            ...styles.tabButton,
            ...(activeTab === tab ? styles.activeTab : styles.inactiveTab)
          }}
          onClick={() => onTabClick(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  )
}

export default TabButtons

const styles = {
  header: {
    display: "flex",
    alignItems: "center",
    gap: 16
  },
  tabButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: "0 0 8px 0",
    marginRight: "16px"
  },
  activeTab: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 500,
    borderBottom: "1px solid #000"
  },
  inactiveTab: {
    color: "#475467",
    fontFamily: "Inter",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    borderBottom: "0px solid #000"
  }
}
