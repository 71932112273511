import { ChevronLeft, ChevronRight } from "react-feather"

const CustomToolbar = toolbar => {
  const goToBack = () => {
    toolbar.onNavigate("PREV")
  }

  const goToNext = () => {
    toolbar.onNavigate("NEXT")
  }

  return (
    <div style={toolbarStyles.container}>
      <div style={toolbarStyles.leftSection}>
        <button
          onClick={() => toolbar.onNavigate("TODAY")}
          style={toolbarStyles.todayButton}
        >
          <span style={toolbarStyles.todayButtonText}>Today</span>
        </button>

        <div style={toolbarStyles.navigationContainer}>
          <button onClick={goToBack} style={toolbarStyles.navigationButton}>
            <ChevronLeft size={35} color="#B1B1B1" />
          </button>
          <button onClick={goToNext} style={toolbarStyles.navigationButton}>
            <ChevronRight size={35} color="#B1B1B1" />
          </button>
        </div>
        <span style={toolbarStyles.label}>{toolbar.label}</span>
      </div>
      <div style={toolbarStyles.viewButtonsContainer}>
        {toolbar.views.map(view => (
          <button
            key={view}
            onClick={() => toolbar.onView(view)}
            style={toolbarStyles.viewButton(view === toolbar.view)}
          >
            <span style={toolbarStyles.viewButtonText(view === toolbar.view)}>
              {view}
            </span>
          </button>
        ))}
      </div>
    </div>
  )
}

// ... rest of the component code ...

// Separate styling object for the toolbar
const toolbarStyles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "30px",
    marginBottom: "30px"
  },
  leftSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  todayButton: {
    display: "flex",
    height: "40px",
    padding: "0px 12px",
    alignItems: "center",
    borderRadius: "4px",
    border: "1px solid #E5E5E5",
    background: "#FFF",
    cursor: "pointer",
    marginRight: "16px"
  },
  todayButtonText: {
    color: "#000",
    textOverflow: "ellipsis",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500
  },
  navigationContainer: {
    display: "flex"
  },
  navigationButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    marginRight: "10px"
  },
  label: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
  },
  viewButtonsContainer: {
    display: "flex",
    gap: "16px",
    backgroundColor: "#F9FAFD",
    borderRadius: "4px"
  },
  viewButton: isActive => ({
    display: "flex",
    height: "40px",
    padding: "0px 12px",
    alignItems: "center",
    borderRadius: "4px",
    border: isActive ? "1px solid #E5E5E5" : "0px",
    background: isActive ? "#FFF" : "#F9FAFD",
    cursor: "pointer"
  }),
  viewButtonText: isActive => ({
    color: isActive ? "#14181F" : "#87898C",
    textOverflow: "ellipsis",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: isActive ? 600 : 500,
    textTransform: "capitalize"
  })
}

export default CustomToolbar
