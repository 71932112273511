import React, { useEffect, useState, useMemo } from "react"

import Modal from "../../../components/Modal"
import InputText from "../../../components/InputText"
import SelectInput from "../../../components/SelectInput"
import Button from "../../../components/Button"
import AssignmentStatusInputs from "../../../components/AssignmentStatusInputs"

import apiRequest from "../../../api/apiService"

const AddNewModal = ({
  isOpen,
  onClose,
  selectedSlot,
  handlAddNewTask,
  edit,
  updateTask
}) => {
  const [activeTab, setActiveTab] = useState("Safety Certificates")
  const [errors, setErrors] = useState({})
  const [users, setUsers] = useState([])

  const formatDate = date => {
    if (!date) return ""
    const d = new Date(date)
    return d.toISOString().split("T")[0]
  }

  const initialFormData = {
    title: edit?.title || "",
    description: edit?.description || "",
    dueDate: edit?.due_date
      ? edit?.due_date
      : selectedSlot?.end
      ? formatDate(selectedSlot.end)
      : "",
    priority: edit?.priority || "",
    status: edit?.status || "TODO",
    users: edit?.usertask_statuses?.map(user => ({
      id: user?.user?.id,
      name: user?.user?.name,
      email: user?.user?.email,
      status: user?.status
    })) || [{}]
  }

  const [formData, setFormData] = useState(initialFormData)

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const addAssignee = () => {
    setFormData({ ...formData, users: [...formData.users, {}] })
  }

  const getAllCompanyUsers = async () => {
    try {
      const response = await apiRequest("GET", "/api/v1/users/list/")

      const users_ = response?.map(user => {
        return { label: user?.name, value: user?.pk, ...user }
      })
      setUsers(users_)
    } catch (error) {
      console.error("Error fetching users:", error)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const newErrors = {}

    Object.keys(formData).forEach(key => {
      if (!formData[key]) {
        newErrors[key] = "This field is required"
      }
    })

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      return
    }

    let hasError = false
    const tempFormDataUsers = [...formData?.users].map(user => {
      const userErrors = {}
      if (!user?.id) {
        userErrors.userIdError = "This field is required"
        hasError = true
      } else {
        userErrors.userIdError = ""
      }
      if (!user?.status) {
        userErrors.userStatusError = "This field is required"
        hasError = true
      } else {
        userErrors.userStatusError = ""
      }

      return { ...user, ...userErrors }
    })

    if (hasError) {
      setFormData({ ...formData, users: tempFormDataUsers })
      return
    }

    if (!edit) {
      handlAddNewTask(formData)
    } else {
      const data = {
        ...formData,
        taskId: edit?.id,
        users: formData?.users.map(user => ({
          id: user?.id,
          status: user?.status
        }))
      }

      updateTask(data)
    }

    setErrors({})
    onClose()
  }

  const onClose_ = () => {
    setFormData("")
    setErrors({})
    onClose()
  }

  const handleSelectUser = (e, index) => {
    const { value } = e.target

    const updatedUsers = [...formData?.users]

    updatedUsers[index] = { ...updatedUsers[index], id: value }
    setFormData({ ...formData, users: updatedUsers })
  }

  const handleSelectStatus = (e, index) => {
    const { value } = e.target

    const updatedUsers = [...formData?.users]

    updatedUsers[index] = { ...updatedUsers[index], status: value }
    setFormData({ ...formData, users: updatedUsers })
  }

  const dropdownUsers = selectedUSer =>
    users.filter(
      user =>
        !formData.users.some(assignedUser => assignedUser.id === user.value)
    )

  const handleRemoveAssignee = index => {
    const updatedUsers = formData.users.filter((_, i) => i !== index)
    setFormData({ ...formData, users: updatedUsers })
  }

  useEffect(() => {
    getAllCompanyUsers()
  }, [])

  if (!isOpen) return null

  return (
    <Modal isOpen={isOpen} onClose={onClose_} title={"Add New"}>
      <div style={styles.container}>
        <div style={styles.tabContainer}>
          {["Tasks"].map(tab => (
            <button
              key={tab}
              style={{
                ...styles.tab,
                ...(true ? styles.activeTab : styles.inActiveTab)
              }}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <InputText
            label="Add Title"
            name="title"
            placeholder="Enter task title"
            value={formData.title}
            onChange={handleInputChange}
            style={styles.input}
            error={errors.title}
            required={true}
          />

          <InputText
            label="Description"
            name="description"
            placeholder="Describe here..."
            value={formData.description}
            onChange={handleInputChange}
            style={styles.input}
            error={errors.description}
            required={true}
          />

          <InputText
            label="Due Date"
            name="dueDate"
            placeholder="Choose a date"
            value={formData.dueDate}
            onChange={handleInputChange}
            style={styles.input}
            type={"date"}
            error={errors.dueDate}
          />

          <SelectInput
            label="Priority"
            name="priority"
            required={true}
            value={formData.priority}
            onChange={handleInputChange}
            options={[
              { value: "low", label: "Low" },
              { value: "medium", label: "Medium" },
              { value: "high", label: "High" }
            ]}
            error={errors.priority}
          />

          {formData?.users?.map((assignedUser, index) => (
            <AssignmentStatusInputs
              key={index}
              formData={formData}
              handleSelectUser={handleSelectUser}
              handleSelectStatus={handleSelectStatus}
              index={index}
              users={dropdownUsers(assignedUser)}
              assignedUser={assignedUser}
              errors={{
                assignTo: assignedUser?.userIdError,
                status: assignedUser?.userStatusError
              }}
              handleRemoveAssignee={handleRemoveAssignee}
            />
          ))}

          {dropdownUsers?.length > 0 && (
            <div onClick={addAssignee} style={styles.addAssigneeText}>
              <span style={styles.addIcon}>+</span> Add Another Assignee
            </div>
          )}

          <div style={styles.buttonContainer}>
            <Button
              onClick={onClose_}
              variant="secondary"
              style={{ marginRight: "8px" }}
            >
              CANCEL
            </Button>

            <Button type="submit" variant="primary">
              {!edit ? "ADD" : "SAVE"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

const styles = {
  container: {
    display: "flex",
    padding: "16px 24px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    alignSelf: "stretch",
    maxHeight: "50%"
  },
  header: {
    fontSize: "24px",
    marginBottom: "20px"
  },
  tabContainer: {
    // display: "flex"
    // paddingTop: "10px"
  },
  tab: {
    background: "none",
    border: "none",
    borderBottom: "1px solid #000",
    cursor: "pointer",
    // color: "#000",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    // lineHeight: "22px",
    marginRight: "16px"
  },
  activeTab: {
    borderBottom: "1px solid black",
    fontWeight: "bold"
  },
  inActiveTab: {
    borderBottom: "0px solid black",
    fontWeight: "bold",
    color: "#475467"
  },
  formGroup: {
    marginBottom: "20px"
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontSize: "14px"
  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxSizing: "border-box"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "16px",
    padding: "12px 0px"
  },
  addAssigneeText: {
    marginTop: "16px",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "#475467",
    fontSize: "14px",
    justifyContent: "flex-end"
  },
  addIcon: {
    marginRight: "8px",
    fontSize: "18px",
    fontWeight: "bold"
  },
  removeButton: {
    display: "flex",
    cursor: "pointer",
    color: "#475467",
    fontSize: "14px",
    alignSelf: "flex-end"
    // backgroundColor: "red"
  }
}

export default AddNewModal
