import React from "react"
import Select from "react-select"

const SelectInput = ({
  label,
  name,
  value,
  onChange,
  options,
  required = false,
  error
}) => {
  const currentValue = options.find(option => option.value === value)

  const handleChange = selectedOption => {
    onChange({ target: { name, value: selectedOption.value } })
  }

  return (
    <div style={styles.formGroup}>
      <label style={styles.label}>
        {label}
        {required && <span style={{ color: "red" }}> *</span>}
      </label>
      <div style={styles.selectWrapper}>
        <Select
          value={currentValue}
          onChange={handleChange}
          options={options}
          styles={customStyles}
        />
        {error && <span style={styles.error}>{error}</span>}
      </div>
    </div>
  )
}

const customStyles = {
  control: provided => ({
    ...provided,
    borderRadius: "10px",
    border: "1px solid var(--Border-colorBorder, #D9D9D9)",
    width: "100%",
    flex: 1
  })
}

const styles = {
  formGroup: {
    marginTop: "16px",
    width: "100%",
    flex: 0.45
  },
  selectWrapper: {
    position: "relative",
    width: "100%",
    marginTop: "8px"
  },
  label: {
    display: "block",
    marginBottom: "5px",
    color: "rgba(0, 0, 0, 0.88)",

    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px"
  },
  selectStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "10px",
    border: "1px solid var(--Border-colorBorder, #D9D9D9)",
    width: "100%",
    background: "white",
    appearance: "none", // Remove default arrow icon
    paddingRight: "40px" // Make space for custom icon
  },
  dropdownIcon: {
    position: "absolute",
    right: "12px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none", // Ensures the icon doesn't interfere with select functionality
    width: "16px",
    height: "16px",
    fill: "#000000" // Adjust color as needed
  },
  error: {
    color: "#ff4d4f",
    fontSize: "14px",
    marginTop: "6px",
  }
}

export default SelectInput
