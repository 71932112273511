import React from "react"
import SelectInput from "./SelectInput"

const selectOptions = [
  { value: "pending", label: "To Do" },
  { value: "in_progress", label: "In Progress" },
  { value: "completed", label: "Completed" }
]

const RemoveAssigneeButton = ({ onClick, index }) => {
  return (
    <div style={styles.flexEnd}>
      <div onClick={() => onClick(index)} style={styles.button}>
        <span style={styles.text}>X</span>
      </div>
    </div>
  )
}

const AssignmentStatusInputs = ({
  handleSelectUser,
  handleSelectStatus,
  errors,
  users,
  index,
  assignedUser,
  handleRemoveAssignee
}) => {
  return (
    <div style={styles.assigneContainer}>
      {index !== 0 && (
        <RemoveAssigneeButton onClick={handleRemoveAssignee} index={index} />
      )}
      <div style={styles.container}>
        <SelectInput
          label="Assign To"
          name="id"
          value={assignedUser.id}
          onChange={props => handleSelectUser(props, index)}
          options={users}
          error={errors.assignTo}
        />
        <SelectInput
          label="Status"
          name="status"
          value={assignedUser.status}
          onChange={props => handleSelectStatus(props, index)}
          options={selectOptions}
          error={errors.status}
        />
      </div>
    </div>
  )
}

export default AssignmentStatusInputs

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  button: {
    backgroundColor: "#fff",
    padding: 5,
    cursor: "pointer",
    borderRadius: 20,
    height: 15,
    width: 15,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  text: {
    color: "black",
    fontSize: 12,
    fontWeight: "bold"
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end"
  },
  assigneContainer: {
    border: "1px solid #f5f5f5",
    padding: 10,
    borderRadius: 8,
    margin: "10px 0px",
    backgroundColor: "#f5f5f5"
  }
}
