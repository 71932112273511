import React from 'react'
import { SyncLoader } from 'react-spinners'

const CustomLoader = ({ isLoading }) => (
    isLoading && (
        <div style={styles.overlay}>
            <SyncLoader loading={isLoading} />
        </div>

    )
)


export default CustomLoader

const styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000
    }
}
