import React from "react"

const CustomEventResizer = ({ event, children }) => (
  <div style={styles.eventResizer}>{children}</div>
)

export default CustomEventResizer

const styles = {
  eventResizer: {
    backgroundColor: "black"
  }
}
