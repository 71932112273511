import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex: 1;
  overflow-y: auto;
`;

export default function Container({ children }) {
  return <StyledContainer>{children}</StyledContainer>
}