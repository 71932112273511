import React from "react"
import styled from "styled-components"
import { ReactComponent as CloseIcon } from "../assets/svg/CloseOutlined.svg"
import Button from "./Button"

const EventPopup = ({ event, position, onClose, handleEdit }) => {
  if (!event) return null

  const UserStatus = ({ userStatus }) => (
    <AssigneeContainer>
      <AssigneeTag>{userStatus.user.name}</AssigneeTag>
      <StatusTag status={userStatus.status}>
        {userStatus.status === "in_progress"
          ? "In Progress"
          : userStatus.status}
      </StatusTag>
    </AssigneeContainer>
  )

  return (
    <PopupContainer top={position.top} left={position.left}>
      <div style={styles.modalHeader}>
        <h2 style={{ ...styles.header, ...styles.longText }}>{event.title}</h2>
        <button style={styles.modalClose} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div style={styles.modalBody}>
        <Title>Description</Title>
        <Description>{event.description}</Description>
        <Field>
          <Label>Priority:</Label>
          <PriorityTag>{event.priority}</PriorityTag>
        </Field>
        <Field>
          <AssigneeContainer>
            <Label>Assignee</Label>
            <Label>Status</Label>
          </AssigneeContainer>
          {event.usertask_statuses.map((userStatus, index) => (
            <UserStatus key={index} userStatus={userStatus} />
          ))}
        </Field>
        <Title>Task Deadline</Title>
        <Value>
          {event.end.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric"
          })}
        </Value>
      </div>

      <div style={styles.buttonContainer}>
        <Button
          onClick={onClose}
          variant="secondary"
          style={{ marginRight: "8px" }}
        >
          CANCEL
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={() => handleEdit(event)}
        >
          EDIT
        </Button>
      </div>
    </PopupContainer>
  )
}

export default EventPopup

const PopupContainer = styled.div`
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 412px;
  z-index: 1000;
`
const Title = styled.h2`
  color: #000;
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 8px;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 24px;
  color: #000;
  font-family: Avenir;
  font-style: normal;
  font-weight: 400;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 100%;
`

const Field = styled.div`
  margin-bottom: 24px;
`

const Label = styled.span`
  font-weight: 600;
  margin-right: 8px;
  color: #000;
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  margin-right: 10px;
`

const Value = styled.span`
  font-weight: 400;
`

const Tag = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
`

const StatusTag = styled(Tag)`
  background-color: ${props => {
    switch (props.status) {
      case "completed":
        return "#e6f4ea" // Green background
      case "Todo":
        return "#fce8e6" // Red background
      case "Pending":
        return "#fff3cd" // Yellow background
      default:
        return "#e6e6e6" // Default background
    }
  }};
  color: ${props => {
    switch (props.status) {
      case "completed":
        return "#137333" // Dark green text
      case "Todo":
        return "#c5221f" // Dark red text
      case "Pending":
        return "#856404" // Dark yellow text
      default:
        return "#000" // Default text color
    }
  }};
  text-transform: uppercase;
`

const PriorityTag = styled(Tag)`
  background-color: ${props => {
    switch (
      props.children // Use props.children to get the priority value
    ) {
      case "low":
        return "#d1e7dd" // Light green background for low
      case "medium":
        return "#fff3cd" // Light yellow background for medium
      case "high":
        return "#f8d7da" // Light red background for high
      default:
        return "#fce8e6" // Default background
    }
  }};
  color: ${props => {
    switch (props.children) {
      case "low":
        return "#0f5132" // Dark green text for low
      case "medium":
        return "#856404" // Dark yellow text for medium
      case "high":
        return "#721c24" // Dark red text for high
      default:
        return "#c5221f" // Default text color
    }
  }};
  text-transform: uppercase;
`

const AssigneeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  margin-top: 8px;
  justify-content: space-between;
`

const AssigneeTag = styled(Tag)`
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

const styles = {
  modalClose: {
    background: "none",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
    color: "#00000073"
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #EDEDED",
    padding: "16px 24px 8px 24px"
  },
  header: {
    color: "var(--Text-colorText, rgba(0, 0, 0, 0.88))",
    fontFamily: "Avenir",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "24px"
  },
  modalBody: {
    padding: "16px 24px"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "12px 24px"
  },
  longText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    width: "90%"
  }
}
