import React from "react"

const InputText = ({
  label,
  name,
  placeholder,
  value,
  onChange,
  type,
  error,
  required = false
}) => {
  return (
    <div style={styles.formGroup}>
      <label style={styles.label}>
        {label}
        {required && <span style={{ color: "red" }}> *</span>}
      </label>
      <input
        name={name}
        placeholder={placeholder}
        style={{ ...styles.input, ...(error ? styles.inputError : {}) }}
        value={value}
        onChange={onChange}
        type={type}
      />
      {error && <div style={styles.errorText}>{error}</div>}
    </div>
  )
}

export default InputText

const styles = {
  formGroup: {
    width: "100%",
    boxSizing: "border-box",
    marginTop: "16px"
  },
  label: {
    display: "block",
    marginBottom: "5px",
    color: "rgba(0, 0, 0, 0.88)",

    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px"
  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "14px",
    height: "40px",
    alignItems: "center",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    flex: "1",
    boxSizing: "border-box"
  },
  inputError: {
    borderColor: "#ff4d4f"
  },
  errorText: {
    color: "#ff4d4f",
    fontSize: "14px",
    marginTop: "4px"
  }
}
