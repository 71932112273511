import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { ReactComponent as CloseIcon } from "../assets/svg/CloseOutlined.svg"

const Modal = ({ isOpen, onClose, children, title, containerStyle }) => {
  useEffect(() => {
    const handleEscapeKey = event => {
      if (event.key === "Escape") {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener("keydown", handleEscapeKey)
    }

    return () => {
      document.removeEventListener("keydown", handleEscapeKey)
    }
  }, [isOpen, onClose])

  if (!isOpen) return null

  return ReactDOM.createPortal(
    <div style={styles.modalOverlay} onClick={onClose}>
      <div
        // style={[styles.modalContent, { containerStyle }]}
        style={{ ...styles.modalContent, ...containerStyle }} // Updated line
        onClick={e => e.stopPropagation()}
      >
        <div style={styles.modalHeader}>
          <h2 style={styles.header}>{title}</h2>
          <button style={styles.modalClose} onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div style={styles.modalBody}>{children}</div>
      </div>
    </div>,
    document.body
  )
}

export default Modal

const styles = {
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000
  },
  modalContent: {
    backgroundColor: "var(--Background-colorBgElevated, #FFF)",
    borderRadius: "8px",
    position: "relative",
    maxWidth: "500px",
    width: "100%",
    maxHeight: "70vh",
    display: "flex",
    flexDirection: "column"
  },
  modalBody: {
    overflowY: "auto",
    padding:
      "var(--Space-Padding-padding, 16px) var(--Space-Padding-paddingLG, 24px)",
    flexGrow: 1
  },
  modalClose: {
    background: "none",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
    color: "#00000073"
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #EDEDED",
    padding:
      "var(--Space-Padding-padding, 16px) var(--Space-Padding-paddingLG, 24px) var(--Space-Padding-paddingXS, 8px) var(--Space-Padding-paddingLG, 24px)"
  },
  header: {
    color: "var(--Text-colorText, rgba(0, 0, 0, 0.88))",
    fontFamily: "Avenir",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "24px"
  }
}
