import React from "react"
import AddTaskIcon from "../../../assets/svg/add_task.svg"

const CustomEvent = ({ event, day = false }) => {
  const backgroundColor =
    event?.event?.status === "completed"
      ? "#CFF2E1"
      : event?.event?.status === "in_progress"
      ? "#FFF3CD"
      : event?.event?.status === "pending"
      ? "#F5D6D6"
      : "#FFFFFF"

  return (
    <div
      style={{
        ...styles.container,
        background: backgroundColor,
        height: day ? "40px" : "25px",
        minHeight: day ? "40px" : "25px"
      }}
    >
      <img src={AddTaskIcon} alt="Add Task" style={styles.icon} />
      <span style={styles.text}>{event?.title}</span>
    </div>
  )
}

export default CustomEvent

const styles = {
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    padding: "4px",
    alignItems: "center",
    gap: "4px",
    borderRadius: "4px",
    border: "none",
    boxSizing: "border-box"
  },
  icon: {
    width: "12px",
    height: "12px",
    marginRight: "4px"
  },
  text: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
  }
}
