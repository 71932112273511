import React, { useState } from "react"

import Modal from "../../../components/Modal"
import InputText from "../../../components/InputText"
import Button from "../../../components/Button"

const formatDate = date => {
  if (!date) return ""
  const d = new Date(date)
  return d.toISOString().split("T")[0]
}

const AdvanceSearchModal = ({
  isOpen,
  onClose,
  handleSearch,
  filters,
  handleClearFilters
}) => {
  const [errors, setErrors] = useState({})
  const initialFormData = {
    employeeName: filters?.employeeName || "",
    dueDate: filters?.dueDate ? formatDate(filters?.dueDate) : ""
  }

  const [formData, setFormData] = useState(initialFormData)

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    handleSearch(formData)
    onClose()
  }

  const onClose_ = () => {
    setFormData("")
    setErrors({})
    onClose()
  }

  if (!isOpen) return null

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose_}
      title={"Advanced Search"}
      containerStyle={{
        maxWidth: 700
      }}
    >
      <div style={styles.container}>
        <form style={{ width: "100%" }}>
          <InputText
            label="Search by Employee"
            name="employeeName"
            placeholder="Search by Employee"
            value={formData.employeeName}
            onChange={handleInputChange}
            style={styles.input}
            error={errors.employeeName}
          />

          <InputText
            label="Due Date"
            name="dueDate"
            placeholder="Choose a date"
            value={formData.dueDate}
            onChange={handleInputChange}
            style={styles.input}
            type={"date"}
            error={errors.dueDate}
          />

          <div style={styles.buttonContainer}>
            <button
              onClick={() => handleClearFilters()}
              style={styles.button}
              type="button"
            >
              <p style={styles.buttonText}>Clear Filters</p>
            </button>

            <div style={styles.buttonAddContainer}>
              <div>
                <Button
                  onClick={onClose_}
                  variant="secondary"
                  style={{ marginRight: "8px" }}
                  type="button"
                >
                  CANCEL
                </Button>
                <Button type="submit" variant="primary" onClick={handleSubmit}>
                  {!filters ? "ADD" : "SAVE"}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    alignSelf: "stretch",
    maxHeight: "50%"
  },
  header: {
    fontSize: "24px",
    marginBottom: "20px"
  },
  tabContainer: {
    // display: "flex"
    // paddingTop: "10px"
  },
  tab: {
    background: "none",
    border: "none",
    borderBottom: "1px solid #000",
    cursor: "pointer",
    // color: "#000",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    // lineHeight: "22px",
    marginRight: "16px"
  },
  activeTab: {
    borderBottom: "1px solid black",
    fontWeight: "bold"
  },
  inActiveTab: {
    borderBottom: "0px solid black",
    fontWeight: "bold",
    color: "#475467"
  },
  formGroup: {
    marginBottom: "20px"
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontSize: "14px"
  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxSizing: "border-box"
  },
  buttonAddContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "16px",
    padding: "12px 0px"
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  addAssigneeText: {
    marginTop: "16px",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "#475467",
    fontSize: "14px",
    justifyContent: "flex-end"
  },
  addIcon: {
    marginRight: "8px",
    fontSize: "18px",
    fontWeight: "bold"
  },
  button: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "4px",
    border: "0px solid #F0F0F0",
    padding: "0px 16px",
    background: "none",
    cursor: "pointer",
    alignItems: "center"
  },
  buttonText: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  }
}

export default AdvanceSearchModal
