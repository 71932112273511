import React from "react"

const CustomWeekHeader = ({ date, label }) => (
  <div style={styles.container}>
    <span style={styles.label}>{label}</span>
  </div>
)

export default CustomWeekHeader

const styles = {
  container: {
    backgroundColor: "#F9FAFD",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "uppercase",
    padding: "14px",
  },
  label: {
    color: "#5F5F5F",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
  }
}
