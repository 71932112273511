import React from "react"

const Button = ({
  onClick,
  type = "button",
  variant = "primary",
  children,
  style
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      style={{ ...baseStyle, ...style, ...variantStyles[variant] }}
    >
      {children}
    </button>
  )
}

export default Button

const baseStyle = {
  padding: "10px 40px",
  borderRadius: "var(--BorderRadius-borderRadius, 6px)",
  cursor: "pointer",
  fontSize: "16px",
  fontStyle: "normal",
  lineHeight: "normal",
}

const variantStyles = {
  primary: {
    background: "#000",
    color: "#FFF",
    border: "none",
    fontFamily: '"Major Snafu"',
    fontWeight: 400
  },
  secondary: {
    background: "white",
    border: "1px solid #ccc",
    color: "#000",
    fontWeight: 500,
    fontFamily: '"Major Snafu"',
  }
}