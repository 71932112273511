import React, { useCallback, useEffect, useState } from "react"
import { Calendar, momentLocalizer, Views } from "react-big-calendar"
import moment from "moment"
import { toast } from "react-hot-toast"
import styled from "styled-components"

import EventPopup from "../../components/EventPopup"
import TabButtons from "../../components/TabButtons"
import CustomToolbar from "./components/CustomToolbar"
import CustomEvent from "./components/CustomEvent"
import AddNewModal from "./components/AddNewTaskModal"
import Container from "../../components/Container"
import CustomLoader from "../../components/CustomLoader"
import CustomDateHeader from "./components/CustomDateHeader"
import CustomEventResizer from "./components/CustomEventResizer"
import CustomWeekHeader from "./components/CustomWeekHeader"
import Search from "../../assets/svg/search.svg"

import apiRequest from "../../api/apiService"
import { pushNotification } from "../../utils/notification"
import AdvanceSearchModal from "./components/AdvanceSearchModal"

const tabs = ["All", "Tasks"]

const localizer = momentLocalizer(moment)

const MyCalendar = () => {
  const [events, setEvents] = useState([])

  const [activeTab, setActiveTab] = useState("All")
  const [selectedSlot, setSelectedSlot] = useState(null)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 })
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const [advanceSearchModalOpen, setAdvanceSearchModalOpen] = useState(false)
  const [filters, setFilters] = useState(false)

  const openAddNewModal = () => setIsAddNewModalOpen(true)
  const closeAddNewModal = () => setIsAddNewModalOpen(false)

  const openFilterModal = () => setAdvanceSearchModalOpen(true)
  const closeFilterModal = () => setAdvanceSearchModalOpen(false)

  const onSelectSlot = useCallback(slotInfo => {
    setSelectedSlot(slotInfo)
    setEdit(false)
    openAddNewModal()
  }, [])

  const onSelectEvent = useCallback((event, e) => {
    const rect = e.target.getBoundingClientRect()
    setPopupPosition({
      top: rect.bottom,
      left: rect.left
    })
    setSelectedEvent(event)
  }, [])

  const closePopup = () => {
    setSelectedEvent(null)
  }

  const fetchTasks = async () => {
    const params = []
    if (filters?.employeeName) {
      params.push(`search=${filters?.employeeName}`)
    }

    if (filters?.dueDate) {
      params.push(`due_date=${filters?.dueDate}`)
    }

    const paramsQuery_ = params.join("&")

    const endpoint = `/api/v1/user-task/${
      paramsQuery_ ? `?${paramsQuery_}` : ""
    }`

    try {
      const response = await apiRequest("GET", endpoint)

      const formattedTasks = response?.result.map(task => {
        const status = task.usertask_statuses.reduce((acc, userStatus) => {
          if (userStatus.status === "pending") return "pending"
          if (userStatus.status === "in_progress") return "in_progress"
          return acc
        }, "completed")

        return {
          title: task.title,
          start: moment(task.due_date).startOf("day").toDate(),
          end: moment(task.due_date).startOf("day").add(15, "minutes").toDate(),
          status,
          ...task
        }
      })

      setEvents(formattedTasks)
    } catch (error) {
      console.error("Error fetching tasks:", error)
      throw error
    }
  }

  const fetchAndToast = async () => {
    toast.promise(fetchTasks(), {
      loading: "Loading tasks...",
      success: "Tasks loaded successfully!",
      error: "Failed to load tasks."
    })
  }

  const handlAddNewTask = async taskFormData => {
    setIsLoading(true)

    const data = {
      title: taskFormData?.title,
      priority: taskFormData?.priority,
      description: taskFormData?.description,
      due_date: taskFormData?.dueDate,
      users: taskFormData?.users
    }

    try {
      await apiRequest("POST", "/api/v1/user-task/", data)
      pushNotification("Task added successfully!", "success")
      fetchAndToast()
    } catch (error) {
      pushNotification(
        error?.response?.data?.message ||
          "Error adding task. Please try again.",
        "error"
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleEdit = event => {
    setEdit(event)
    closePopup()
    openAddNewModal()
  }

  const updateTask = async formData => {
    const data = {
      title: formData?.title,
      priority: formData?.priority,
      description: formData?.description,
      due_date: formData?.dueDate,
      users: formData?.users
    }
    const endPoint = `/api/v1/user-task/${edit?.id}/`
    try {
      await apiRequest("PUT", endPoint, data)
      pushNotification("Task Updated successfully!", "success")
      fetchAndToast()
    } catch (error) {
      pushNotification(
        error?.response?.data?.message ||
          "Error updating task. Please try again.",
        "error"
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchAndToast()
  }, [filters?.employeeName, filters?.dueDate])

  const CusDateCellWrapper = () => {
    return <div style={styles.dateCellWrapper} />
  }

  const handleSearch = async formData => {
    setFilters(formData)
  }

  const handleClearFilters = () => {
    setFilters(false)
    closeFilterModal()
  }

  const Header = () => {
    return (
      <>
        <div style={styles.tabContainer}>
          <TabButtons
            tabs={tabs}
            activeTab={activeTab}
            onTabClick={setActiveTab}
            styles={styles}
          />
          <button style={styles.button} onClick={openFilterModal}>
            <img src={Search} alt="Add Task" style={{ marginRight: 8 }} />
            <h1 style={styles.buttonText}>Advanced Search</h1>
          </button>
        </div>

        {(filters.employeeName || filters.dueDate) && (
          <div style={styles.appliedFilters}>
            Applied Filters:
            {filters?.employeeName && (
              <FilterBadge>{filters?.employeeName}</FilterBadge>
            )}
            {filters?.dueDate && <FilterBadge>{filters?.dueDate}</FilterBadge>}
          </div>
        )}
      </>
    )
  }

  return (
    <Container>
      <Header />
      <CalenderWrapper>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={styles.calendar}
          onSelectEvent={onSelectEvent}
          defaultView={Views.MONTH}
          components={{
            toolbar: CustomToolbar,
            event: event => <CustomEvent event={event} />,
            dateHeader: CustomDateHeader,
            eventResizer: CustomEventResizer,
            header: CustomWeekHeader,
            timeSlotWrapper: CusDateCellWrapper,
            timeSlotGroupWrapper: CusDateCellWrapper,
            day: {
              event: event => <CustomEvent day={true} event={event} />
            }
          }}
          titleAccessor="title"
          onSelectSlot={onSelectSlot}
          selectable
          dayPropGetter={() => ({
            style: calendarComponentStyles.dayBackground
          })}
          eventPropGetter={() => ({ style: calendarComponentStyles.event })}
        />
      </CalenderWrapper>

      {selectedEvent && (
        <EventPopup
          event={selectedEvent}
          position={popupPosition}
          onClose={closePopup}
          handleEdit={handleEdit}
        />
      )}

      {isAddNewModalOpen && (
        <AddNewModal
          isOpen={isAddNewModalOpen}
          onClose={closeAddNewModal}
          selectedSlot={selectedSlot}
          handlAddNewTask={handlAddNewTask}
          edit={edit}
          updateTask={updateTask}
        />
      )}
      {advanceSearchModalOpen && (
        <AdvanceSearchModal
          isOpen={advanceSearchModalOpen}
          onClose={closeFilterModal}
          handleSearch={handleSearch}
          filters={filters}
          handleClearFilters={handleClearFilters}
        />
      )}

      {isLoading && <CustomLoader isLoading={isLoading} />}
    </Container>
  )
}

export default MyCalendar

const calendarComponentStyles = {
  dayBackground: {
    cursor: "pointer",
    transition: "background-color 0.2s ease",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },
  event: {
    backgroundColor: "transparent",
    border: "0px solid #000",
    color: "#000",
    position: "relative",
    width: "100%",
    left: "0%"
  }
}

const styles = {
  header: {
    display: "flex",
    alignItems: "center",
    gap: 16
  },
  tabButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: "0 0 8px 0",
    marginRight: "16px"
  },
  activeTab: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 500,
    borderBottom: "1px solid #000"
  },
  inactiveTab: {
    color: "#475467",
    fontFamily: "Inter",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    borderBottom: "0px solid #000"
  },
  calendar: {
    flex: 1,
    background: "white"
  },
  dateCellWrapper: {
    backgroundColor: "red",
    border: "black",
    borderWidth: "10px",
    display: "flex",
    flexDirection: "column"
  },
  button: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "4px",
    border: "1px solid #F0F0F0",
    padding: "0px 16px",
    background: "none",
    cursor: "pointer",
    alignItems: "center"
  },
  buttonText: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  },
  tabContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  appliedFilters: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    color: "#000",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    alignSelf: "flex-end"
  }
}

const CalenderWrapper = styled.div`
  flex: 1;
`

const FilterBadge = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  padding: 0px 16px;
  background: none;
  align-items: center;
  margin-left: 10px;
`
