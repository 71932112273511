import React from "react"

const CustomDateHeader = ({ label }) => (
  <div style={styles.dateHeader}>
    <span style={styles.dateHeaderLabel}>{label}</span>
  </div>
)

export default CustomDateHeader

const styles = {
  dateHeader: {
    fontWeight: "bold",
    color: "#333",
    display: "flex",
    justifyContent: "start",
    padding: "10px"
  },
  dateHeaderLabel: {
    color: "#5F5F5F",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
  }
}
